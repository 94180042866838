import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Input from "../../components/Input/Input";
import LoginPic from "../../assets/Logotipo_boraver.png";
import { Content, Form, LeftSide, RightSide } from "./styles";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services";
import Button from "../../components/Button/Button";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";

const Login = () => {
  const { setAuth, auth } = useAuth();
  const { setUserId } = useClient();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }, [])

  const schema = yup.object().shape({
    email: yup.string().email("Email inválido!").required("Campo obrigatório!"),
    password: yup
      .string()
      .min(4, "A senha deve conter no mínimo 4 caracteres!")
      .required("Campo obrigatório!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const registerNewClient = async (client) => {
    // api
    //   .post("/token/", client)
    //   .then((res) => {
    //       console.log(res);
    //       localStorage.setItem("userBoraVer", JSON.stringify(res.data));
    //       setAuth(res.data.token)
    //       setUserId(res.data.user.id)
    //       toast.success("Bem vindo!")
    //       return history.push('/dashboard')
    //   })
    //   .catch((err) => toast.error("Email ou senha inválidos!"))
    let response;
    setLoading(true);
    
    try {
      response = await api.post("/token/", client);
    } catch (error) {
      setLoading(false);
      toast.error("Email ou senha inválidos!");
      return;
    }
    
    try {
      const responseClient = await api.get(
        `/api/v3/clienteusuario/${response.data.user.id}/`,
        {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        }
      );
      
      if (responseClient.data.results.length > 0) {
        setLoading(false);
        setAuth(response.data.token);
        setUserId(response.data.user.id);
        toast.success("Bem vindo!");
        localStorage.setItem("@boraver", JSON.stringify(response.data));
        // return history.push('/dashboard')
      } else {
        setLoading(false);
        throw "usuário inexistente";
      }
    } catch (error) {
      setLoading(false);
      toast.error("Influenciador, utilize o app para fazer login");
      return;
    }
  };

  const handleNavigation = (path) => {
    return history.push(path);
  };

  useEffect(() => {
    if (auth) {
      return history.push("/dashboard");
    }
  }, []);

  return (
    <div>
      {/* <Header /> */}
      <Content>
        <img src={LoginPic} />
        <Form onSubmit={handleSubmit(registerNewClient)}>
          {/* <h2>Faça seu login</h2> */}
          <Input
            register={register}
            name="email"
            label="Seu e-mail"
            error={errors.email?.message}
          />
          <Input
            register={register}
            type="password"
            name="password"
            label="Sua senha"
            error={errors.password?.message}
          />
          <span>
            Ainda não possui cadastro? Cadastre-se{" "}
            <a onClick={() => handleNavigation("/signup")}>aqui</a>
          </span>
          <span>
            Esqueceu sua senha? Clique{" "}
            <a onClick={() => handleNavigation("/reset-request")}>aqui</a>
          </span>
          <div className="btn-place">
            <Button
              setBackgroundColor="var(--yellow)"
              setColor="var(--black)"
              type="submit"
              style={{ width: "100%" }}
              disabled={loading}
            >
              Entrar
            </Button>
          </div>
          <div
            style={{ marginInline: "auto", textAlign: "center", width: "90%" }}
          >
            Criando sua conta, você aceita os{" "}
            <a href="#" style={{ color: "var(--black)" }}>
              Termos de Serviço
            </a>{" "}
            e{" "}
            <a href="#" style={{ color: "var(--black)" }}>
              Politicas de Privacidade.
            </a>
          </div>
        </Form>
        {/* <LeftSide>
        </LeftSide>
        <RightSide>
          <img src={LoginPic} alt="login-boraver" />
        </RightSide> */}
      </Content>
      {/* <Footer /> */}
    </div>
  );
};
export default Login;
