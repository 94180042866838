import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import { Container, Loading } from "../Solicitations/styles";
import Button from "../../components/Button/Button";
import { Content, DivRow } from "./styles";
import { getEstado } from "../../services/estados";
import { useAuth } from "../../providers/auth";
import { Estados } from "../../utils/enum";

const ModalEditorCampaign = ({
  titleModalCampanha,
  payload,
  loadingEditCampanha,
  handlePayload,
  submitCampanha,
  setModalVisibleEditPachage
}) => {
  const { auth } = useAuth();
  const [estados, setEstados] = useState([]);

  useEffect(() => {
    getEstados()
  }, [])

  const getEstados = async () => {
    try {
      const result = await getEstado(auth)
      if (result) {
        const _results = result.results.map((item) => {
          return {
            id: item.id,
            nome: reverseEstados[item.nome],
            criado_em: item.criado_em
          }
        })

        setEstados(_results);
        handlePayload("estados", _results[0]?.id);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  }

  const reverseEstados = Object.keys(Estados).reduce((acc, key) => {
    acc[Estados[key]] = key;
    return acc;
  }, {});

  return (
    <Modal
      title={titleModalCampanha}
      setShouldOpenModal={() => setModalVisibleEditPachage(false)}
    >
      <div>
        <div>
          <Container
            style={{
              flexDirection: "column",
              marginTop: 0,
              minHeight: "unset",
            }}
          >
            <label htmlFor="nome">Nome da oferta</label>
            <input
              type="text"
              name="nome"
              id="nome"
              value={payload.nome ? payload.nome : ""}
              style={{ width: "100%" }}
              onChange={({ target }) => handlePayload("nome", target.value)}
            />
            <DivRow style={{ marginBottom: 10 }}>
              <Content style={{ width: "48%", minHeight: "fit-content", padding: 0, marginLeft: 0 }}>
                <label htmlFor="valorServico">Valor</label>
                <input
                  type="number"
                  name="valorServico"
                  id="valorServico"
                  value={payload.valor ? payload.valor : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) => handlePayload("valor", target.value)}
                />
              </Content>
              <Content style={{ width: "48%", minHeight: "fit-content", padding: 0 }}>
                <label htmlFor="data_inicio">Início</label>
                <input
                  type="date"
                  name="data_inicio"
                  id="data_inicio"
                  value={payload.data_inicio ? payload.data_inicio : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) => handlePayload("data_inicio", target.value)}
                />
              </Content>
            </DivRow>
            <DivRow style={{ marginBottom: 10 }}>
              <Content style={{flexDirection: "column", width: "48%", minHeight: "fit-content", padding: 0, marginLeft: 0 }}>
                <label htmlFor="">Estado</label>
                <select
                    value={payload.estados}
                    style={{ padding: "12px 10px" }}
                    onChange={({target}) => handlePayload('estados', +target.value)}
                >
                  <option value="" selected disabled>Selecione</option>
                  {estados.map((item) => (
                      <option key={item.id} value={item.id}>
                          {item.nome}
                      </option>
                  ))}
                </select>
              </Content>
              <Content style={{ flexDirection: "column", width: "48%", minHeight: "fit-content", padding: 0 }}>
                <label htmlFor="data_termino">Término</label>
                <input
                  type="date"
                  name="data_termino"
                  id="data_termino"
                  value={payload.data_termino ? payload.data_termino : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) =>
                    handlePayload("data_termino", target.value)
                  }
                />
              </Content>
            </DivRow>
            <label htmlFor="descricao">Descrição detalhada</label>
            <textarea
              id="descricao"
              value={payload.descricao ? payload.descricao : ""}
              style={{ width: "100%", height: "auto" }}
              rows={10}
              onChange={({ target }) =>
                handlePayload("descricao", target.value)
              }
            />
          </Container>

          <DivRow style={{ justifyContent: "flex-end" }}>
            {loadingEditCampanha ? <Loading style={{ margin: 10 }} /> : null}
            <Button
              setBackgroundColor={"var(--white)"}
              type="button"
              disabled={loadingEditCampanha}
              onClick={() => submitCampanha()}
              style={{ border: "1px solid var(--black)" }}
            >
              Editar Campanha
            </Button>
          </DivRow>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditorCampaign;
