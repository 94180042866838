import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Content,
  DivColumn,
  DivRow,
  Subtitle,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Title,
} from "./styles";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { DivBetween } from "../VitrineCreators/styles";
import api from "../../services";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import { formatDate, formatDateHour } from "../../utils/helpers";
import { FaArrowLeft, FaArrowRight, FaCheck, FaRegUserCircle } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { TbArrowBackUp } from "react-icons/tb";
import { toast } from "react-toastify";
import { DivCenter } from "../ListCreators/styles";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner";
import { alterStatusCampanha } from "../../providers/solicitation";
import { toReal } from "../../utils/currency";

const DetailCampaign = () => {
  const { auth } = useAuth();
  const { clientId } = useClient();
  const location = useLocation();
  const history = useHistory();
  const page = useRef(1);
  const totalPages = useRef();
  const [campanha, setCampanha] = useState();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    if (location && location.state) {
      const { state } = location;
      const id = state.id;

      setCampanha(state);
    } else {
      history.goBack();
    }
  }, [location]);

  return (
    <Content>
      <DivBetween>
        <DivRow className="align-center" onClick={() => history.goBack()} style={{ cursor: "pointer", marginBottom: 10, width: "fit-content" }}>
          <TbArrowBackUp size={34} title="Voltar" />
          <p style={{ width: "fit-content" }}>Voltar</p>
        </DivRow>
        <Button onClick={() => history.push({ pathname: "/candidatures", state: campanha})}>Ver Candidaturas</Button>
      </DivBetween>
      <Card width="100%">
        <div style={{ marginTop: 0, marginBottom: 14 }}>
          <Title size="24px">Descrição da campanha</Title>
          <Subtitle size="28px">{campanha?.nome_campanha}</Subtitle>
        </div>
        <div style={{ overflowX: "auto" }}>
          <p>{campanha?.descricao}</p>

          <Subtitle size="24px">Período</Subtitle>
          <p>De {campanha?.data_inicio ? formatDate(campanha.data_inicio) : ""} à {campanha?.data_termino ? formatDate(campanha.data_termino) : ""}</p>

          {campanha?.segmentos.length > 0 ? (
            <>
              <Subtitle size="24px">Segmento</Subtitle>
              <p>{campanha?.segmentos.map(item => item.nome).join(", ")}</p>
            </>
          ) : null}

          <Subtitle size="24px">Redes Sociais</Subtitle>
          <p>{campanha?.redes_sociais.map(item => item.nome).join(", ")}</p>
          
          <Subtitle size="24px">Tipo de Remuneração</Subtitle>
          <p>{campanha?.remuneracoes.map(item => item.nome).join(", ")}</p>
          
          <Subtitle size="24px">Valor Ofertado</Subtitle>
          <p>{campanha?.valor ? toReal(+campanha.valor) : "R$ 0,00"}</p>

          <Subtitle size="24px">Quantidade de Vagas</Subtitle>
          <p>{campanha?.quantidade_vagas}</p>
        </div>
      </Card>
    </Content>
  );
};

export default DetailCampaign;
